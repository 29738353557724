import React from "react";
import * as app from "../fetch/fetchApi";

const BannerMensaje = () => {
  return (
    <div class="notice" style={{ position: 'relative', zIndex: '9999', background: 'orange', textAlign: 'center', fontWeight: '500', color: 'white', fontSize: ' .85em', padding: '3px 0' }}>
      <a style={{ color: 'white', textDecoration: 'underline', fontWeight: 'bold' }}>Del 05 al 08 de diciembre camping no estará disponible, te invitamos a conocer estos días otro hospedaje del paraíso.</a>
    </div >
  );
};

export default BannerMensaje;